.ngGrid {
  background-color: #fdfdfd;
}
.ngGrid input[type="checkbox"] {
  margin: 0;
  padding: 0;
}
.ngGrid input {
  vertical-align: top;
}
.ngGrid.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.ngViewport {
  overflow: auto;
  min-height: 20px;
}
.ngViewport:focus {
  outline: none;
}
.ngCanvas {
  position: relative;
}
.ngVerticalBar {
  position: absolute;
  right: 0;
  width: 0;
}
.ngVerticalBarVisible {
  width: 1px;
  background-color: #d4d4d4;
}
#testDataLength {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -100000;
}
.ngHeaderContainer {
  position: relative;
  overflow: hidden;
  font-weight: bold;
  background-color: inherit;
}
.ngHeaderCell {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: inherit;
}
.ngHeaderCell.pinned {
  z-index: 1;
}
.ngHeaderSortColumn {
  position: absolute;
  overflow: hidden;
}
.ngTopPanel {
  position: relative;
  z-index: 1;
  background-color: #eaeaea;
  border-bottom: 1px solid #d4d4d4;
}
.ngSortButtonDown {
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-color: gray transparent;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  height: 0;
  width: 0;
}
.ngNoSort {
  cursor: default;
}
.ngHeaderButton {
  position: absolute;
  right: 2px;
  top: 8px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  z-index: 1;
  background-color: #9fbbb4;
  cursor: pointer;
}
.ngSortButtonUp {
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-color: gray transparent;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  height: 0;
  width: 0;
}
.ngHeaderScroller {
  position: absolute;
  background-color: inherit;
}
.ngSortPriority {
  position: absolute;
  top: -5px;
  left: 1px;
  font-size: 6pt;
  font-weight: bold;
}
.ngHeaderGrip {
  cursor: col-resize;
  width: 10px;
  right: -5px;
  top: 0;
  height: 100%;
  position: absolute;
  background-color: transparent;
}
.ngHeaderText {
  padding: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ngHeaderButtonArrow {
  position: absolute;
  top: 4px;
  left: 3px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.5px 4.5px 0 4.5px;
  border-color: #4d4d4d transparent transparent transparent;
}
.ngPinnedIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABp0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuMTAw9HKhAAAAmElEQVQoU33PQapBURjA8UtkwJuaWYGSgfQWYBMvczPmTCzAAGVuaA228BZhRCkDGSmE31FucuRfvzq3vr5zT/JSjSU7DsypEPXDkDVn2hSIytJhw4kWGaLCxgHh2gt/RBuLzNhz5caWPjnSqqw4EraFfwznf8qklWjwy4IRTerkiQoPGtPl40OehcEJvcfXl8LglLfBJLkDcMgbgHlHhK8AAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 10px;
  width: 10px;
}
.ngUnPinnedIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABp0RVh0U29mdHdhcmUAUGFpbnQuTkVUIHYzLjUuMTAw9HKhAAAAlElEQVQoU33PPQrCQBRF4fFnI2KfZVi5ARvdgo1l6mwmkCJVOgluwd5OwUoDtnoOxAei8cLXTN7cvEl/skCNDCMPfsUPO5zQwOHIDEvYtMURHe6wOVLgigvOePRyeDkyR4ln7wZ//7XfFBu8B23+aDJjrHGAwza7hjtHJvDmHg7b7Bru7AMjK7Rw2ObBVHDY5oGk9AKQNB2zy8MBTgAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  position: absolute;
  height: 10px;
  width: 10px;
  right: 5px;
  top: 5px;
}
.ngColMenu {
  right: 2px;
  padding: 5px;
  top: 25px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #bdd0cb;
  position: absolute;
  border: 2px solid #d4d4d4;
  z-index: 1;
}
.ngColListCheckbox {
  position: relative;
  right: 3px;
  top: 4px;
}
.ngColList {
  list-style-type: none;
  margin-top: 2px;
  margin-left: 8px;
}
.ngColListItem {
  white-space: nowrap;
}
.ngMenuText {
  position: relative;
  top: 2px;
  left: 2px;
}
.ngGroupPanel {
  background-color: #eaeaea;
  overflow: hidden;
  border-bottom: 1px solid #d4d4d4;
}
.ngGroupPanelDescription {
  margin-top: 5px;
  margin-left: 5px;
}
.ngGroupList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.ngAggHeader {
  position: absolute;
  border: none;
}
.ngGroupElement {
  float: left;
  height: 100%;
  width: 100%;
}
.ngGroupIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAEFJREFUKFNjoAhISkr+h2J5JDZODNXGwGBsbPwfhIGAA8bGh6HaGBiAGhxAGJmND4M1gQCSM0adCsVQbcPcqQwMALWDGyDvWPefAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  position: absolute;
  right: -2px;
  top: 2px;
}
.ngGroupedByIcon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAANCAYAAACZ3F9/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAElJREFUKFNjoAhISkr+R8LyaHwMDNXGwGBsbPwfhoGAA5mPDUO1oWpE52PDYE0gALTFAYbR+dgwWBMIoPlh1I9ADNU2NPzIwAAAFQYI9E4OLvEAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  position: absolute;
  right: -2px;
  top: 2px;
}
.ngGroupName {
  background-color: #fdfdfd;
  border: 1px solid #d4d4d4;
  padding: 3px 10px;
  float: left;
  margin-left: 0;
  margin-top: 2px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-weight: bold;
}
.ngGroupArrow {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid black;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  float: right;
}
.ngGroupingNumber {
  position: absolute;
  right: -10px;
  top: -2px;
}
.ngAggArrowCollapsed {
  position: absolute;
  left: 8px;
  bottom: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8.7px;
  border-color: transparent transparent transparent #000000;
}
.ngGroupItem {
  float: left;
}
.ngGroupItem:first-child {
  margin-left: 2px;
}
.ngRemoveGroup {
  width: 5px;
  -moz-opacity: 0.4;
  opacity: 0.4;
  margin-top: -1px;
  margin-left: 5px;
}
.ngRemoveGroup:hover {
  color: black;
  text-decoration: none;
  cursor: pointer;
  -moz-opacity: 0.7;
  opacity: 0.7;
}
.ngAggArrowExpanded {
  position: absolute;
  left: 8px;
  bottom: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 9px 9px;
  border-color: transparent transparent #000000 transparent;
}
.ngAggregate {
  position: absolute;
  background-color: #c9dde1;
  border-bottom: 1px solid beige;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: -1px;
  left: 0;
}
.ngAggregateText {
  position: absolute;
  left: 27px;
  top: 5px;
  line-height: 20px;
  white-space: nowrap;
}
.ngRow {
  position: absolute;
  border-bottom: 1px solid #d4d4d4;
}
.ngRow.odd {
  background-color: #fdfdfd;
}
.ngRow.even {
  background-color: #f3f3f3;
}
.ngRow.selected {
  background-color: #c9dde1;
}
.ngCell {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: inherit;
}
.ngCell.pinned {
  z-index: 1;
}
.ngCellText {
  padding: 5px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ngSelectionCell {
  margin-top: 9px;
  margin-left: 6px;
}
.ngSelectionHeader {
  position: absolute;
  top: 11px;
  left: 6px;
}
.ngCellElement:focus {
  outline: 0;
  background-color: #b3c4c7;
}
.ngRow.canSelect {
  cursor: pointer;
}
.ngSelectionCheckbox {
  margin-top: 9px;
  margin-left: 6px;
}
.ngFooterPanel {
  background-color: #eaeaea;
  padding: 0;
  border-top: 1px solid #d4d4d4;
  position: relative;
}
.nglabel {
  display: block;
  float: left;
  font-weight: bold;
  padding-right: 5px;
}
.ngTotalSelectContainer {
  float: left;
  margin: 5px;
  margin-top: 7px;
}
.ngFooterSelectedItems {
  padding: 2px;
}
.ngFooterTotalItems.ngnoMultiSelect {
  padding: 0 !important;
}
.ngGridMaxPagesNumber {
  vertical-align: middle;
}
.ngPagerFirstBar {
  width: 10px;
  border-left: 2px solid #4d4d4d;
  margin-top: -6px;
  height: 12px;
  margin-left: -3px;
}
.ngPagerButton {
  height: 25px;
  min-width: 26px;
}
.ngPagerFirstTriangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 8.7px 5px 0;
  border-color: transparent #4d4d4d transparent transparent;
  margin-left: 2px;
}
.ngPagerNextTriangle {
  margin-left: 1px;
}
.ngPagerPrevTriangle {
  margin-left: 0;
}
.ngPagerLastTriangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 8.7px;
  border-color: transparent transparent transparent #4d4d4d;
  margin-left: -1px;
}
.ngPagerLastBar {
  width: 10px;
  border-left: 2px solid #4d4d4d;
  margin-top: -6px;
  height: 12px;
  margin-left: 1px;
}
.ngFooterTotalItems {
  padding: 2px;
}
