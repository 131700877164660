.customer--display {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}
.loading-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.loading-indicator .center-this {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-indicator .center-this-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading-indicator div.spinner {
  position: relative;
  width: 54px;
  height: 54px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  padding: 10px;
  border-radius: 10px;
}
.loading-indicator div.spinner div {
  width: 6%;
  height: 16%;
  background: #fff;
  position: absolute;
  left: 49%;
  top: 43%;
  opacity: 0;
  -webkit-border-radius: 50px;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  -webkit-animation: fade 1s linear infinite;
}
@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
.loading-indicator div.spinner div.bar1 {
  -webkit-transform: rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
}
.loading-indicator div.spinner div.bar2 {
  -webkit-transform: rotate(30deg) translate(0, -130%);
  -webkit-animation-delay: -0.9167s;
}
.loading-indicator div.spinner div.bar3 {
  -webkit-transform: rotate(60deg) translate(0, -130%);
  -webkit-animation-delay: -0.833s;
}
.loading-indicator div.spinner div.bar4 {
  -webkit-transform: rotate(90deg) translate(0, -130%);
  -webkit-animation-delay: -0.7497s;
}
.loading-indicator div.spinner div.bar5 {
  -webkit-transform: rotate(120deg) translate(0, -130%);
  -webkit-animation-delay: -0.667s;
}
.loading-indicator div.spinner div.bar6 {
  -webkit-transform: rotate(150deg) translate(0, -130%);
  -webkit-animation-delay: -0.5837s;
}
.loading-indicator div.spinner div.bar7 {
  -webkit-transform: rotate(180deg) translate(0, -130%);
  -webkit-animation-delay: -0.5s;
}
.loading-indicator div.spinner div.bar8 {
  -webkit-transform: rotate(210deg) translate(0, -130%);
  -webkit-animation-delay: -0.4167s;
}
.loading-indicator div.spinner div.bar9 {
  -webkit-transform: rotate(240deg) translate(0, -130%);
  -webkit-animation-delay: -0.333s;
}
.loading-indicator div.spinner div.bar10 {
  -webkit-transform: rotate(270deg) translate(0, -130%);
  -webkit-animation-delay: -0.2497s;
}
.loading-indicator div.spinner div.bar11 {
  -webkit-transform: rotate(300deg) translate(0, -130%);
  -webkit-animation-delay: -0.167s;
}
.loading-indicator div.spinner div.bar12 {
  -webkit-transform: rotate(330deg) translate(0, -130%);
  -webkit-animation-delay: -0.0833s;
}
.timestampModifierModal {
  font-family: 'Lato', Helvetica, Arial, sans-serif !important;
  font-weight: 700;
}
.timestampModifierModal .form-section-sub-title {
  font-size: 1.6rem;
  color: #333333;
  height: 25%;
  font-weight: 400;
}
.timestampModifierModal .time_date_area {
  display: flex;
  height: 75%;
  width: 100%;
  padding-top: 2%;
}
.timestampModifierModal .time_date_area .time_text_area {
  height: 100%;
  border: 1px #d8d8d8 solid;
  border-right: unset;
  font-size: 130%;
  background: #fff !important;
  text-align: center;
  color: #000 !important;
  opacity: 1;
  -webkit-appearance: initial;
}
.timestampModifierModal .time_date_area .time_period_area {
  height: 100%;
  border: 1px #d8d8d8 solid;
  font-size: 130%;
  background: #fff !important;
  color: #000 !important;
  opacity: 1;
  -webkit-appearance: initial;
}
.timestampModifierModal .time_date_area input {
  padding: 0;
}
.timestampModifierModal .time_date_area .date_selector_box {
  height: 100%;
}
.timestampModifierModal .time_date_area .date_selector_box .date_selector_area {
  height: 100%;
  font-size: 130%;
  background: #fff !important;
  color: #000 !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -webkit-appearance: initial;
  text-align: center;
}
.timestampModifierModal .time_date_area .date_selector_box .dropdown-menu {
  left: auto !important;
  right: 0px;
}
.timestampModifierModal .time_date_area .ui-select-container {
  width: 100%;
  height: 100%;
  background: #fff !important;
  padding: unset !important;
}
.timestampModifierModal .time_date_area .ui-select-container .btn-default-focus {
  background: unset !important;
  box-shadow: unset !important;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-search {
  height: 100%;
  padding-left: 10%;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-choices {
  min-width: 100%;
  height: 250%;
  padding: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-clip: unset !important;
  box-shadow: unset;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-choices .ui-select-choices-group {
  height: 100%;
  width: 100%;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-choices .ui-select-choices-group .ui-select-choices-row {
  height: 50%;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-choices .ui-select-choices-group .ui-select-choices-row .ui-select-choices-row-inner {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
  border: 1px solid #ddd;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-choices .ui-select-choices-group .ui-select-choices-row.active > span {
  color: #000;
  background-color: unset;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-match {
  height: 100%;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-match .caret {
  /*background: url(/images/dropdownarrow.svg) center center no-repeat;
                    border-top: unset;
                    position: unset;
                    width: 30%;
                    height: 30%;*/
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-match .ui-select-placeholder {
  padding-left: 1%;
  font-size: 100%;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-match .ui-select-match-text {
  padding-left: 10%;
  display: flex;
  align-items: center;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-match .ui-select-match-text span {
  text-align: center;
}
.timestampModifierModal .time_date_area .ui-select-container .ui-select-match .btn {
  background: unset !important;
  color: #000 !important;
  padding: unset !important;
  border-radius: 0px !important;
  border: unset !important;
  font-size: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10%;
  text-shadow: unset !important;
  margin: 0px;
}
.loyalty .loyalty-inner {
  align-items: center;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.loyalty .loyalty-close-button {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 26px;
  height: 75px;
  line-height: 75px;
  text-align: center;
  width: 75px;
  z-index: 3;
  color: #aaa;
}
.loyalty .loyalty-patron-photo {
  background-color: #ffffff;
  z-index: 3;
}
.loyalty .loyalty-patron-photo-big {
  background-color: #f8f8f8;
  border: 2px solid #dddddd;
  border-radius: 50%;
  height: 110px;
  width: 110px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  margin-bottom: -20px;
  z-index: 2;
}
.loyalty .loyalty-info {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loyalty .loyalty-info hr {
  width: 100%;
  border-top: 1px solid #ddd;
  margin-bottom: 10px;
  margin-top: 20px;
}
.loyalty .loyalty-info .loyalty-title {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.loyalty .loyalty-info .loyalty-title .loyalty-title-star {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  border: 3px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loyalty .loyalty-info .loyalty-title .loyalty-title-star i {
  color: #dedede;
  font-size: 30px;
}
.loyalty .loyalty-info .loyalty-title .loyalty-title-text {
  text-align: left;
  font-size: 30px;
  font-weight: 600;
  padding-left: 20px;
}
.loyalty .loyalty-info .loyalty-info-inner {
  height: 120px;
  margin: 15px;
  position: relative;
  border-radius: 10px;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 3;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container .loyalty-patron-name {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  margin: 5px 0 10px 0;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container .loyalty-patron-tier {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: #ccc;
  margin-bottom: 10px;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container .loyalty-patron-tier .loyalty-patron-tier-label {
  padding: 2.5px 15px;
  font-weight: 500;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container .loyalty-patron-tier .loyalty-patron-tier-label-active {
  color: #333;
  background: #efe13b;
  font-weight: 500;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container .loyalty-points {
  font-weight: 500;
  text-align: center;
  font-size: 16px;
}
.loyalty .loyalty-info .loyalty-info-inner .loyalty-patron-container .loyalty-level {
  height: 100%;
  padding: 20px 0 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  border-radius: 0 0 8px 0;
  color: #000;
}
