@font-face {
  font-family: 'Knedlika';
  src: url('/fonts/Knedlika.eot?rj9r74');
  src: url('/fonts/Knedlika.eot?rj9r74#iefix') format('embedded-opentype'), url('/fonts/Knedlika.ttf?rj9r74') format('truetype'), url('/fonts/Knedlika.woff?rj9r74') format('woff'), url('/fonts/Knedlika.svg?rj9r74#Knedlika') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.nown-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Knedlika' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-Checkmark-Outlined:before {
  content: "\E900";
  color: #47bf52;
}
.icon-X-Filled:before {
  content: "\E911";
  color: #b35959;
}
.icon-Checkmark-Filled:before {
  content: "\E912";
  color: #3af03a;
}
.icon-Person-Add .path1:before {
  content: "\E903";
  color: #333332;
}
.icon-Person-Add .path2:before {
  content: "\E904";
  margin-left: -1em;
  color: #efe13a;
}
.icon-Person-Add .path3:before {
  content: "\E905";
  margin-left: -1em;
  color: #333332;
}
.icon-Star:before {
  content: "\E906";
  color: #efe13a;
}
.icon-Calendar:before {
  content: "\E907";
}
.icon-Location:before {
  content: "\E908";
}
.icon-Lock-Large:before {
  content: "\E909";
}
.icon-Scan-App:before {
  content: "\E90B";
}
.icon-Bag-Large:before {
  content: "\E90D";
}
.icon-Mobile-Order:before {
  content: "\E90E";
}
.icon-Hamburger:before {
  content: "\E90F";
}
.icon-Eject:before {
  content: "\E910";
}
.icon-Messages:before {
  content: "\E914";
}
.icon-Receipt-Both:before {
  content: "\E916";
}
.icon-Receipt-Customer:before {
  content: "\E917";
}
.icon-Print:before {
  content: "\E922";
}
.icon-Email:before {
  content: "\E923";
}
.icon-Spinner:before {
  content: "\E926";
}
.icon-Reset-Large:before {
  content: "\E927";
}
.icon-History-Large:before {
  content: "\E928";
}
.icon-Clock:before {
  content: "\E929";
}
.icon-Warning:before {
  content: "\E92A";
}
.icon-Folder:before {
  content: "\E92B";
}
.icon-List:before {
  content: "\E92C";
}
.icon-Radio-Large-Empty:before {
  content: "\E92D";
}
.icon-Checkbox-Large-Empty:before {
  content: "\E930";
  color: #ccccc8;
}
.icon-Checkbox-Large-Checked:before {
  content: "\E933";
}
.icon-Radio-Large-Partial:before {
  content: "\E934";
}
.icon-Radio-Large-Filled:before {
  content: "\E936";
}
.icon-Note:before {
  content: "\E938";
}
.icon-Edit-Large:before {
  content: "\E93A";
}
.icon-Barcode-QR:before {
  content: "\E93B";
}
.icon-Barcode-Regular:before {
  content: "\E93C";
}
.icon-Delete:before {
  content: "\E93D";
}
.icon-Exit-Large:before {
  content: "\E93F";
}
.icon-Receipt-Single:before {
  content: "\E940";
}
.icon-Download-Large:before {
  content: "\E943";
}
.icon-Arrow-Large-Down:before {
  content: "\E944";
}
.icon-Arrow-Large-Up:before {
  content: "\E945";
}
.icon-Arrow-4way:before {
  content: "\E946";
}
.icon-Arrow-Large-Left:before {
  content: "\E94A";
}
.icon-Arrow-Large-Right:before {
  content: "\E94B";
}
.icon-Setup-Large:before {
  content: "\E94D";
}
.icon-Log-Out:before {
  content: "\E94E";
}
.icon-Barcode-Scan:before {
  content: "\E94F";
}
.icon-Search-Large:before {
  content: "\E951";
}
.icon-Card:before {
  content: "\E952";
}
.icon-Products:before {
  content: "\E953";
}
.icon-Home-Large:before {
  content: "\E955";
}
.icon-CurrencyExchange:before {
  content: "\E956";
}
.icon-Analytics:before {
  content: "\E957";
}
.icon-Plus-Large:before {
  content: "\E95A";
}
.icon-Help:before {
  content: "\E95B";
}
.icon-Cyclist2:before {
  content: "\E95C";
}
.icon-Cyclist1:before {
  content: "\E95D";
}
.icon-Web-Order:before {
  content: "\E95E";
}
.icon-Marketing:before {
  content: "\E95F";
}
.icon-Kiosk:before {
  content: "\E960";
}
.icon-ODS:before {
  content: "\E961";
}
.icon-Tag:before {
  content: "\E962";
}
.icon-Discount:before {
  content: "\E963";
}
.icon-Person-Single:before {
  content: "\E964";
}
.icon-Person-Multiple:before {
  content: "\E965";
}
.icon-Icon-Exit-Small:before {
  content: "\E901";
}
.icon-Lock-Small:before {
  content: "\E90A";
}
.icon-Bag-Small:before {
  content: "\E90C";
}
.icon-History-Small:before {
  content: "\E924";
}
.icon-Spinner-Small:before {
  content: "\E925";
}
.icon-Checkbox-Small-Empty:before {
  content: "\E92F";
  color: #ccccc8;
}
.icon-Checkbox-Small-Partial:before {
  content: "\E931";
}
.icon-Checkbox-Small-Checked:before {
  content: "\E932";
}
.icon-Edit-Small:before {
  content: "\E939";
}
.icon-Arrow-Small-Right:before {
  content: "\E941";
}
.icon-Download-Small:before {
  content: "\E942";
}
.icon-Arrow-Small-Down:before {
  content: "\E947";
}
.icon-Arrow-Small-Up:before {
  content: "\E948";
}
.icon-Arrow-Small-Left:before {
  content: "\E949";
}
.icon-Setup-Small:before {
  content: "\E94C";
}
.icon-Search-Small:before {
  content: "\E950";
}
.icon-Home-Small:before {
  content: "\E954";
}
.icon-Minus-Small:before {
  content: "\E958";
}
.icon-Plus-Small:before {
  content: "\E959";
}
.icon-empty:before {
  content: "";
}
