.freshideas-logo {
  height: 35px;
  padding-top: 2px;
}
.profile-info-name {
  position: relative;
  width: auto;
  text-align: left;
  padding: 6px 10px 6px 6px;
}
.profile-info-value {
  margin-left: 0;
}
/** Modal Padding Fix */
.remove-margin-withTop {
  margin: 20px -20px -20px -20px;
}
.remove-margin-withoutTop {
  margin: 0 -20px -20px -20px;
}
/* centered columns styles */
.row-centered {
  text-align: center;
}
.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
}
/* Adds separation to columns that stack*/
.col-margin-top {
  margin-top: 5px;
}
/* Adjusts padding for fa-icons in nav-list */
.nav-list > li > a {
  padding: 10px 16px 10px 11px;
  height: auto;
  line-height: 18px;
}
.nav-list > li.open .dropdown-menu {
  display: block;
}
.nav-list > li .submenu > li a > [class*="fa-"]:first-child {
  display: none;
  font-size: 12px;
  font-weight: normal;
  width: 18px;
  height: auto;
  line-height: 12px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 11px;
  z-index: 1;
  background-color: #fff;
}
.nav-list > li .submenu > li.active > a > [class*="fa-"]:first-child,
.nav-list > li .submenu > li:hover > a > [class*="fa-"]:first-child {
  display: inline-block;
}
.nav-list > li .submenu > li.active > a > [class*="fa-"]:first-child {
  color: #1963aa;
}
.tooltip {
  z-index: 10000;
}
/* Added this to get a handle on the multi select element*/
#customMulti input[type="text"] {
  width: 100px !important;
}
/** UI Utils */
.separator-right {
  border-right: 1px dotted #e2e2e2;
  padding-right: 10px;
}
.separator-left:before {
  content: "";
  top: 3px;
  bottom: 3px;
  left: -1px;
  border: 1px solid #d9d9d9;
  border-width: 0 1px 0 0;
  padding-right: 4px;
  margin-right: 2px;
  margin-left: 2px;
}
.separator {
  padding-left: 5px;
}
.width-100 {
  width: 100%;
}
.width-50 {
  width: 30%;
}
.normal-weight {
  font-weight: normal;
  font-size: 13px;
}
.bold-weight {
  font-weight: bold;
}
.btn-padding {
  padding: 3px 6px;
}
.padding-left-15 {
  padding-left: 15px;
}
.padding-left-2 {
  padding-left: 2px;
}
.padding-left-2:first-child {
  padding-left: 0px;
}
.padding-left-5 {
  padding-left: 5px;
}
.padding-right-15 {
  padding-right: 15px;
}
.padding-right-5 {
  padding-right: 5px;
}
.padding-right-2 {
  padding-right: 2px;
}
.padding-right-2:last-child {
  padding-right: 0px;
}
.padding-10 {
  padding: 10px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-top-8 {
  margin-top: 8px;
}
.margin-top-10 {
  margin-top: 10px;
}
.minus-top-10 {
  margin-top: -10px;
}
.minus-top-15 {
  margin-top: -15px;
}
@media (max-width: 992px) {
  .align-left-md {
    text-align: left !important;
  }
}
/** Typography */
/** Override ace label font weight (revert to default bootstrap) */
label {
  font-weight: 600;
}
.radio label,
.checkbox label {
  font-weight: 600;
}
/* Override font styles to make things darker */
.header-adjust {
  margin-top: -6px;
}
h4 small {
  color: #2679b5;
}
a {
  cursor: pointer;
}
i.icon-beaker {
  cursor: pointer;
}
.icon-yellow {
  color: #f7d05b !important;
  border-color: #fee188;
}
.icon-red {
  color: darkred !important;
  border-color: red;
}
.page-header h4 {
  font-weight: lighter;
  color: #2b7c36;
}
a.freshideas-help i {
  text-decoration: none;
}
/** Smaller alert box */
.alert.alert-sm {
  font-size: inherit;
  padding: 10px;
}
.login-modal .alert-info {
  font-size: 20px;
}
.sticky {
  position: fixed;
  top: 0px;
}
/** Override ace button for timepicker & datepicker*/
.freshideas-timepicker .btn.btn-default,
.freshideas-datepicker .btn.btn-default {
  color: #333 !important;
  background-color: #fff !important;
  text-shadow: none !important;
  box-shadow: none;
  border-color: #ccc !important;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/** Updates ace styles for new font-awesome */
.input-icon > [class*="fa-"] {
  padding: 0 3px;
  z-index: 2;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 3px;
  line-height: 28px;
  display: inline-block;
  color: #909090;
  font-size: 16px;
}
.input-icon.input-icon-right > [class*="fa-"] {
  left: auto;
  right: 3px;
}
.input-icon > input:focus + [class*="fa-"] {
  color: #579;
}
.freshideas-datepicker .btn-info:hover,
.freshideas-datepicker .btn-info:focus,
.freshideas-datepicker .btn-info:active,
.freshideas-datepicker .btn-info.active {
  color: #fff !important;
  background-color: #39b3d7 !important;
  border-color: #269abc !important;
}
.input-group-addon i.icon-btn {
  cursor: pointer;
}
/** record info to be used with bootstrap pagination with max 5 pages showing */
@media (min-width: 992px) {
  .freshideas-pager > .record-info {
    position: absolute;
    right: 30px;
    bottom: 16px;
  }
}
.freshideas-pager > .record-info {
  text-align: right;
}
/** Legend to be used with table / pager */
@media (min-width: 992px) {
  .freshideas-pager > .pager-legend {
    position: absolute;
    left: 5px;
    bottom: 10px;
  }
}
.freshideas-pager > .pager-legend {
  text-align: right;
}
.freshideas-pager .pager,
.freshideas-pager .pagination {
  margin: 0px;
}
.bg-info {
  background-color: #d9edf7;
}
.bg-active {
  background-color: #f5f5f5;
}
.bg-success {
  background-color: #dff0d8;
}
.bg-warning {
  background-color: #fcf8e3;
}
.bg-danger {
  background-color: #f2dede;
}
/** Override bootstrap pager margin */
.freshideas-data-table .freshideas-pager .pager {
  margin: 0px;
}
/** Allows for ellipsis on pre-defined column widths */
.td-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 1px;
}
.nav-tabs {
  font-size: 14px;
}
.nav-tabs.nav-justified > li {
  cursor: pointer;
}
.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > .active > a:focus {
  border-bottom-color: #fff;
  cursor: pointer;
}
.tabs-blue > .nav-tabs > li.active > a,
.tabs-blue > .nav-tabs > li.active > a:focus,
.tabs-blue > .nav-tabs > li.active > a:hover {
  color: #4c718a;
}
.tabs-blue > .nav-tabs > li.active > a,
.tabs-blue > .nav-tabs > li.active > a:focus,
.tabs-blue > .nav-tabs > li.active > a:hover {
  background-color: #FFF;
  color: #4f80a0;
  box-shadow: none;
}
.tabs-blue > .nav-tabs > li > a,
.tabs-blue > .nav-tabs > li > a:focus {
  background-color: #7db4d8;
}
.tabs-blue > .nav-tabs > li > a,
.tabs-blue > .nav-tabs > li > a:focus,
.tabs-blue > .nav-tabs > li > a:hover {
  color: #FFF;
  margin-right: 3px;
}
/** Nested List */
.dd-handle:focus {
  background-color: #fee188;
}
.dd-handle.selected {
  background: #e4efc9;
}
/** Override bootstrap **/
.receipt.pre-scrollable {
  height: 500px;
  width: 100%;
  min-width: 0;
  border: 1px solid #d3d3d3;
  position: relative;
}
/** App Search List **/
.dd-desc {
  color: #aaa;
  display: block;
  overflow: hidden;
  font-weight: normal;
  line-height: 1.4em;
  font-size: 11px;
}
.dd-option {
  margin-right: 10px;
  padding: 5px;
  display: block;
  border-bottom: solid 1px #ddd;
  overflow: hidden;
  text-decoration: none;
  color: #333;
  cursor: pointer;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
}
.dd-option:hover {
  background: #f3f3f3;
  color: #000;
}
.dd-option.selected {
  background: #e4efc9;
}
.dd-option-image {
  vertical-align: middle;
  float: left;
  margin-right: 7px;
  max-width: 64px;
}
.dd-container {
  position: relative;
}
.dd-option-text {
  color: inherit;
  display: block;
  overflow: hidden;
  font-size: 13px;
}
/** Forms */
/** Add padding to every column in an freshideas-form */
.freshideas-form [class*="col-"] {
  padding-top: 4px;
  padding-bottom: 4px;
}
.freshideas-form > label {
  font-size: inherit;
}
.form-label {
  color: #000000;
}
.freshideas-form [class*="col-"].form-label {
  padding-right: 4px;
  vertical-align: middle;
  color: #000000;
  font-weight: bold;
}
@media (min-width: 768px) {
  .freshideas-form [class*="col-"].form-label {
    text-align: right;
  }
}
.freshideas-form .control-label {
  font-size: 12px;
  font-weight: bold;
}
.form-horizontal.freshideas-form .form-group {
  margin-right: 0px;
  margin-left: 0px;
}
.control-label {
  font-size: 13px;
}
.form-inline-space {
  margin-left: 10px!important;
}
.search-form .row {
  padding: 5px;
}
.form-table label {
  padding-right: 5px;
}
.form-table th {
  padding: 3px;
  text-align: right;
  font-size: 12px;
}
.form-table td {
  padding: 3px;
  text-align: left;
}
.form-table .row {
  border-top: 1px dotted #D5E4F1;
}
.form-table .row:first-child {
  border: none;
}
.form-table tr {
  border-top: 1px dotted #D5E4F1;
}
.form-table .form-label {
  text-align: right;
  font-weight: bold;
}
.form-help-inline {
  color: #b94a48;
  padding-left: 5px;
}
/** Put an asterick after these labels */
.freshideas-req-label:after {
  content: " *";
  color: #b03535;
}
.clear-btn {
  border: 0px solid;
  padding-right: 20px;
  padding-top: 0px;
  height: 60px;
  line-height: 15px;
  float: left;
  outline: 0px;
}
.input-full {
  font-size: 20px !important;
  width: 100%;
  height: 100%;
  background: #EEE !important;
  outline: none !important;
  border: 0px !important;
}
.scaleBounce {
  animation: scaleBounce 0.75s 0s;
  transform: scale(0.85);
}
@keyframes scaleBounce {
  0% {
    transform: scale(1.1);
    opacity: 1;
  }
  50% {
    transform: scale(1.6);
    opacity: .7;
  }
  60% {
    transform: scale(0.6);
    opacity: 1;
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.85);
  }
}
/*input.ng-pristine {*/
/*box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;*/
/*}*/
form input:required.ng-dirty.ng-invalid,
.freshideas-form input:required.ng-dirty.ng-invalid,
form input:required.ng-empty.ng-invalid.submitted {
  border-color: #FF0300;
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  min-width: 100px;
  /*max-width: 250px;*/
}
form input:required.ng-dirty.ng-valid,
.freshideas-form input:required.ng-dirty.ng-valid {
  /* border-color: #28921f; */
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  /* padding-right: 30px; */
  min-width: 100px;
  /*max-width: 250px;*/
}
form textarea:required.ng-dirty.ng-invalid,
.freshideas-form textarea:required.ng-dirty.ng-invalid {
  border-color: #FF0300;
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  min-width: 100px;
  /*max-width: 250px;*/
}
form textarea:required.ng-dirty.ng-valid,
.freshideas-form textarea:required.ng-dirty.ng-valid {
  /* border-color: #28921f; */
  box-shadow: 0px 0px 3px #ccc, 0 10px 15px #eee inset;
  padding-right: 30px;
  min-width: 100px;
  /*max-width: 250px;*/
}
/** Override backgrounds for login forms */
.login-form input:required.ng-dirty.ng-invalid {
  background: inherit;
}
.login-form input:required.ng-dirty.ng-valid {
  background: inherit;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  padding-right: 30px;
  -webkit-appearance: none;
  margin: 0;
  pointer-events: none;
  /* we are hiding the spinner, so don't allow any click events either */
}
.modal.modal-numpad .modal-dialog {
  width: auto !important;
  border: 0px;
  box-shadow: none;
}
.modal.modal-numpad .modal-header {
  display: none;
}
.modal.modal-numpad .modal-content {
  border: 0px !important;
}
.modal.modal-numpad.modal-right {
  text-align: right;
}
.modal.modal-numpad.modal-numpad--wide .modal-dialog {
  width: 50% !important;
}
.modal.modal-fullscreen {
  background: none;
}
.modal.modal-fullscreen:before {
  display: none;
}
.modal.modal-fullscreen .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0 0;
}
.modal.modal-fullscreen-right .modal-dialog .modal-content {
  align-items: flex-end !important;
}
.modal.modal-fullscreen .modal-dialog .modal-content {
  width: 100%;
  height: 100%;
  background-color: rgba(241, 247, 244, 0.9);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-header {
  width: 100%;
  height: 90px;
  background: none !important;
  color: #48bb91 !important;
  border-bottom: none;
  font-size: 30px;
  padding: 8px 20px;
  position: relative;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-header .modal-subtitle {
  font-size: 18px;
  font-weight: 300;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-header .modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px 15px;
  font-size: 36px;
  line-height: 48px;
  padding: 0 15px;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  margin: 0 auto;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .list-item-view {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .list-item-view .list-item-view-inner {
  width: 200%;
  height: 100%;
  white-space: nowrap;
  font-size: 0;
  margin-left: 0;
  transition: margin-left 0.25s;
  transition-delay: margin-left 0.25s;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .list-item-view.showSecondary .list-item-view-inner {
  margin-left: -100%;
}
.modal.modal-fullscreen-transparent {
  background-color: rgba(221, 242, 234, 0) !important;
}
.modal.modal-light .numpad-section {
  background-color: #fff !important;
  font-family: 'Open Sans' !important;
}
.modal.modal-light .numpad-section > h1 {
  color: #000 !important;
  border-bottom: solid #ccccc8 1px !important;
  margin: 0 !important;
  padding: 32px 24px !important;
}
.modal.modal-light .modal-content {
  border-radius: 5px !important;
  overflow-y: hidden !important;
}
.list-item-view .list-item-view-inner .view-section {
  width: 50%;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  font-size: 12px;
  white-space: normal;
  opacity: 1;
  transition: opacity 0.25s;
}
.list-item-view .list-item-view-inner .view-section.item-selection-section {
  position: relative;
}
.list-item-view .list-item-view-inner .view-section.is-minimized {
  opacity: 0;
}
.list-item-view .list-item-view-inner .view-section .item-add-button-container {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  margin: 0 15px;
}
.list-item-view .list-item-view-inner .view-section .item-add-button-container .item-add-button {
  width: 24px;
  text-align: center;
}
.list-item-view .list-item-view-inner .view-section .item-add-button-container .item-add-button:hover {
  font-size: 1.2em;
}
.list-item-view .list-item-view-inner .view-section .item-add-button-container .item-add-button > .fa {
  line-height: 36px;
  color: #48bb91;
}
.list-item-view .list-item-view-inner .view-section .list-title {
  font-size: 24px;
  line-height: 36px;
  font-family: 'Oswald';
  color: #48bb91;
  text-align: center;
  margin-bottom: 50px;
}
.list-item-view .list-item-view-inner .view-section .list-container {
  width: 100%;
  min-height: 300px;
}
.list-item-view .list-item-view-inner .view-section .list-container .list-item {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  color: #48bb91;
  cursor: pointer;
}
.list-item-view .list-item-view-inner .view-section .list-container .list-item.list-item-printer,
.list-item-view .list-item-view-inner .view-section .list-container .list-item.list-item-pos-station {
  color: #444444;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
}
.list-item-view .list-item-view-inner .view-section .list-container .list-item.list-item-printer:hover,
.list-item-view .list-item-view-inner .view-section .list-container .list-item.list-item-pos-station:hover {
  border: 1px solid #48bb91;
}
.list-item-view .list-item-view-inner .view-section .list-container-empty {
  width: 100%;
}
.list-item-view .list-item-view-inner .view-section .list-container-empty .list-container-empty-message {
  width: 100%;
  margin: 0 auto;
  padding: 30px;
  font-size: 18px;
  font-weight: 300;
  border: 1px dashed #48bb91;
  text-align: center;
}
.list-item-view .list-item-view-inner .view-section .list-container-empty .list-container-empty-message.actionable-message:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.list-item-view .list-item-view-inner .view-section.item-edit-section {
  position: relative;
}
.list-item-view .list-item-view-inner .view-section .item-edit-exit-button-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  margin: 0 15px;
  cursor: pointer;
}
.list-item-view .list-item-view-inner .view-section .item-edit-exit-button-container .item-edit-exit-button {
  width: 24px;
  text-align: center;
}
.list-item-view .list-item-view-inner .view-section .item-edit-exit-button-container .item-edit-exit-button:hover {
  font-size: 1.2em;
}
.list-item-view .list-item-view-inner .view-section .item-edit-exit-button-container .item-edit-exit-button > .fa {
  line-height: 36px;
  color: #48bb91;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container {
  width: 100%;
  min-height: 300px;
  position: relative;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form-title {
  font-size: 24px;
  line-height: 36px;
  font-family: 'Oswald';
  color: #48bb91;
  text-align: center;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form {
  text-align: center;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-section {
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  background-color: rgba(72, 187, 145, 0.05);
  border: 1px solid rgba(72, 187, 145, 0.1);
  color: #48bb91;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-section .form-section-content .flex-content {
  display: flex;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-section .form-section-content .form-subsection-label {
  margin: 10px auto 0;
  color: #48bb91;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 700;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group {
  margin: 12px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group.disabled {
  opacity: 0.3;
  pointer-events: none;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group label.form-input-label {
  display: block;
  margin: 10px auto 0;
  color: #48bb91;
  font-size: 14px;
  text-transform: uppercase;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group label.form-value-label {
  display: block;
  font-size: 28px;
  font-weight: 400;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input {
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(72, 187, 145, 0.4);
  padding: 12px 12px;
  margin: 0 auto 8px;
  width: 100%;
  font-size: 28px;
  text-align: center;
  background: none;
  font-weight: 300;
  color: #444444;
  height: 54px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input[readonly] {
  background: none !important;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input.form-input-text-sm {
  font-size: 20px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input:focus {
  border-bottom: 1px solid #48bb91;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input.font-xxs {
  font-size: 14px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input.font-xs {
  font-size: 18px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input.font-sm {
  font-size: 24px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group select.form-input {
  height: 54px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group input[type='number']::-webkit-inner-spin-button,
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  apperance: none;
  margin: 0;
  display: none;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-color-input {
  border: 1px solid #444444;
  outline: none;
  margin: 9px;
  width: 100%;
  font-size: 0;
  text-align: center;
  color: #444444;
  width: 36px;
  height: 36px;
  display: inline-block;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-options {
  width: 100%;
  padding: 8px 0;
  display: flex;
  justify-content: center;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-options .form-option {
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border: 1px solid #48bb91;
  border-left: none;
  color: #48bb91;
  cursor: pointer;
  transition: background-color 0.1s;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-options .form-option:hover {
  background-color: rgba(72, 187, 145, 0.2);
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-options .form-option:nth-child(1) {
  border-left: 1px solid #48bb91;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-options .form-option.selected {
  border-right: 1px solid #ddf2ea;
  background-color: #48bb91;
  color: #ffffff;
  font-weight: 700;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input-toggle {
  width: 48px;
  height: 48px;
  margin: 8px 0;
  border: 1px solid rgba(72, 187, 145, 0.5);
  border-radius: 50%;
  background: none;
  padding: 0;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input-toggle:hover {
  border: 2px solid #48bb91;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input-toggle.active {
  border: 2px solid #48bb91;
  background: #48bb91;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input-toggle.active:hover {
  background: rgba(72, 187, 145, 0.8);
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .form-input-group .form-input-toggle.active:after {
  content: '';
  display: block;
  width: 12px;
  height: 20px;
  border-style: solid;
  border-color: #ffffff;
  border-width: 0 5px 5px 0;
  transform: rotate(40deg);
  margin-left: 16px;
  margin-top: -4px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .item-delete-button {
  border: none;
  background: none;
  margin: 15px 15px;
  font-size: 16px;
}
.list-item-view .list-item-view-inner .view-section .item-edit-form-container .item-edit-form .item-edit-button {
  padding: 20px;
  border: 0;
}
.inventory-category-items-container {
  padding-top: 10px;
}
.pos-category {
  position: relative;
  width: 100%;
  padding: 20px 30px;
  background: #FFF;
  color: #333;
  font-size: 16px;
}
.pos-category-input {
  position: relative;
  width: 100%;
  background: #FFF;
  color: #333;
  font-size: 16px;
}
.pos-category-input input {
  padding: 20px 30px;
  font-size: 16px;
  width: 100%;
}
.pos-category-delete {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -15px;
}
.pos-category-selected {
  background: #EEE;
}
.pos-btn-inactive {
  opacity: 0.3;
  border: 1px dashed #333;
}
.pos-btn-inactive button {
  background: #FFF !important;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-input-view {
  width: 50%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-input-view .search-input-view-section {
  position: relative;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-input-view .search-input-label {
  width: 100%;
  margin: 10px auto 0;
  color: #48bb91;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-input-view .search-input {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid #48bb91;
  text-align: center;
  padding: 10px 15px;
  margin: 15px 0;
  font-size: 28px;
  color: #48bb91;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-input-view .list-all-button {
  position: absolute;
  top: 54px;
  right: 0;
  background-color: rgba(72, 187, 145, 0.15) !important;
  color: #48bb91 !important;
  padding: 8px 15px;
  border-radius: 12px;
  border: none  !important;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: none !important;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-input-view .list-all-button.is-active {
  background-color: #48bb91 !important;
  color: #ffffff !important;
}
.modal.modal-fullscreen .modal-dialog .modal-content .modal-body .search-results-view .search-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.search-results-view .search-results .search-result-container {
  width: 60%;
  min-width: 240px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}
.search-results-view .search-results .search-result-container:before {
  content: " ";
  display: block;
  padding-top: 70px;
}
.search-results-view .search-results .search-result-container .search-result {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 15px;
  right: 120px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.search-results-view .search-results .search-result-container .search-result:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.search-results-view .search-results .search-result-container .search-result .search-result-inner {
  width: 100%;
}
.search-results-view .search-results .search-result-container .search-result .result-actions {
  width: 72px;
  padding: 0 8px;
  text-align: center;
}
.search-results-view .search-results .search-result-container .search-result .result-actions .result-action {
  color: #48bb91;
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  transition: font-size 0.125s cubic-bezier(0.46, -0.45, 0.13, 1.97);
}
.search-results-view .search-results .search-result-container .search-result:hover .result-actions .result-action {
  font-size: 20px;
}
.search-results-view .search-results .search-result-container .search-result .result-title {
  padding: 0 8px;
  font-size: 24px;
  font-family: Oswald;
  font-weight: 300;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.search-results-view .search-results .search-result-container .search-result .result-description {
  padding: 0 8px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  align-self: flex-end;
}
.search-results-view .search-results .search-result-container .search-result .result-description .lighter {
  font-weight: 400;
  font-size: 0.75em;
  color: #999999;
}
.search-results-view .search-results .search-result-container .search-result-action-options {
  position: absolute;
  top: 5px;
  right: 0;
  bottom: 5px;
  width: 120px;
}
.search-results-view .search-results .search-result-container .search-result-action-options .result-action-option {
  width: 50%;
  height: 60px;
  background-color: rgba(72, 187, 145, 0.15);
  color: #48bb91;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}
.search-results-view .search-results .search-result-container .search-result-action-options .result-action-option:hover {
  font-size: 18px;
  background-color: rgba(72, 187, 145, 0.3);
}
.search-results-view .search-results .search-result-empty-message {
  width: 100%;
  padding: 20px 15px;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  color: #444444;
}
@media screen and (min-width: 768px) {
  .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
  .modal-login.modal:before {
    display: inline-block;
    vertical-align: top !important;
    content: " ";
    height: 100%;
  }
}
@media (min-width: 768px) {
  .modal-90 .modal-dialog {
    width: 90%;
  }
  .modal-80 .modal-dialog {
    width: 80%;
  }
  .modal-70 .modal-dialog {
    width: 70%;
  }
  .modal-60 .modal-dialog {
    width: 60%;
  }
  .modal-50 .modal-dialog {
    width: 50%;
  }
  .modal-40 .modal-dialog {
    width: 40%;
  }
  .modal-30 .modal-dialog {
    width: 30%;
  }
  .modal-autowidth .modal-dialog {
    width: auto;
  }
  .modal-tender .modal-dialog {
    width: 960px;
  }
}
.modal-sidebar {
  width: 190px;
  float: left;
  position: relative;
  border: 1px solid #ccc;
  border-width: 0 1px 0 0;
  background-color: #f2f2f2;
  max-height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media (min-width: 768px) {
  .videoHeightSmall {
    height: 200px;
  }
}
@media (min-width: 992px) {
  .videoHeightMedium {
    height: 400px;
  }
}
@media (min-width: 1200px) {
  .videoHeightBig {
    height: 800px;
  }
}
/** Google Maps */
.angular-google-map-container {
  height: 200px;
}
.google-map-400 .angular-google-map-container {
  height: 400px;
}
.google-map-250 .angular-google-map-container {
  height: 250px;
}
.google-map-full .angular-google-map-container {
  height: 100%;
}
#policyForm .container {
  width: initial;
}
/** App Icons */
.app-img-sm {
  height: 40px;
  width: 40px;
}
.rounded-img-sm {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: #999 2px 2px 2px;
  -moz-box-shadow: #999 2px 2px 2px;
  -o-box-shadow: #999 2px 2px 2px;
  box-shadow: #999 2px 2px 2px;
}
/* Override decorations for menu dropdown toggles here.*/
.submenu > li > a {
  text-decoration: none;
  background-color: #fff;
}
.submenu > li > a:hover {
  text-decoration: none;
  background-color: #f9f9f9;
}
.submenu > li > a:active {
  text-decoration: none;
  color: blue;
}
.nav-list > li .submenu > li.active > a > [class*="icon-"]:first-child {
  color: #1963aa;
}
/** Simple Data Box */
.freshideas-data-box {
  margin: 0 12px;
}
.freshideas-data-box [class*="col-"] {
  font-weight: normal;
  padding: 6px 4px 6px 6px;
}
.freshideas-data-box.light-padding [class*="col-"] {
  font-weight: normal;
  padding: 2px 2px 2px 2px;
}
.freshideas-data-box .row:first-child [class*="col-"] {
  border-top: none;
}
.freshideas-data-box [class*="col-"].freshideas-label {
  color: #667E99;
}
@media (min-width: 768px) {
  .freshideas-data-box [class*="col-"].freshideas-label {
    text-align: right;
  }
}
.freshideas-data-box [class*="col-"].freshideas-value {
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.freshideas-data-box [class*="col-"].freshideas-section-header {
  color: #667E99;
  font-weight: bold;
  border-bottom: 1px dotted #D5E4F1;
}
.no-wrap {
  white-space: nowrap;
}
/** Growl */
.growl {
  position: fixed;
  z-index: 9000;
  top: 20px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
}
/** Ensures timepicker is over modals */
.bootstrap-timepicker-widget {
  z-index: 999999;
}
/** used with validation errors */
/**
This styles the IP and Current Group at the top right of the page
*/
.breadcrumb-user-info {
  margin-right: 10px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 4px;
}
/** freshideas-page-header can be used as alternative to ace header classes */
.freshideas-page-header-row {
  border-bottom: 1px solid #2b7c36;
  margin: 0 0 12px;
}
.freshideas-page-header-row .freshideas-page-header h1 {
  padding: 0;
  font-size: 24px;
  font-weight: normal;
  color: #2b7c36;
}
/** Overrides bootstrap to match ace's control labels */
.form-horizontal .form-control-static {
  padding-top: 3px;
}
.code-box pre {
  font: 0.855em "Courier New", Courier, mono;
}
/* Custom footer for button located in angular-strap popover */
.popover-btn-footer {
  border-top-color: #e4e9ee;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #eff3f8;
  margin: 0px -14px -10px -14px;
  padding: 10px 14px 10px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.well-xs {
  padding: 4px;
  border-radius: 3px;
}
/** Override css for multiselect dropdown */
.multiSelect .multiSelectItem:not(.multiSelectGroup).selected {
  background-image: none;
  cursor: pointer;
  border: none;
}
.multiSelectItem span {
  font-weight: normal;
  font-size: 12px !important;
}
.multiSelect .button {
  font-size: 12px !important;
}
.multiselect,
.checkboxlayer,
.show {
  max-height: 300px;
  overflow-y: scroll;
  min-width: 150px !important;
  float: left;
}
.multiselect,
.checkboxlayer,
.show {
  max-height: 300px;
  overflow-y: scroll;
  min-width: 150px !important;
  float: left;
}
.simple-chart div.no-data {
  height: 100%;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: bold;
}
.simple-chart div.no-data span {
  position: relative;
  top: 50%;
}
/** Animations */
.ng-enter {
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  animation: fadeIn 1s;
}
.ng-enter,
.ng-leave.ng-leave-active {
  opacity: 0;
}
.ng-leave,
.ng-enter.ng-enter-active {
  opacity: 1;
}
/**
 * NG Grid
 */
.data-grid {
  border: 1px solid #d4d4d4;
  width: 100%;
}
.data-grid.client-side-fixed-height {
  height: 400px;
}
.data-grid.small-height {
  height: 200px;
}
.data-grid .grid-footer {
  width: 100%;
  border-top: 1px solid #d4d4d4;
}
.data-grid .grid-footer .pager {
  margin: 0px 0;
}
/** Kill horizontal overflow on 100% width grids */
.data-grid.grid-100 .ngViewport {
  overflow-x: hidden;
}
.data-grid .ngHeaderCell {
  background: #f2f2f2;
  background-image: -webkit-gradient(linear, left 0, left 100%, from(#f8f8f8), to(#ececec));
  background-image: -webkit-linear-gradient(top, #f8f8f8, 0%, #ececec, 100%);
  background-image: -moz-linear-gradient(top, #f8f8f8 0, #ececec 100%);
  background-image: linear-gradient(to bottom, #f8f8f8 0, #ececec 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
}
.ngRow {
  border: 1px solid #d4d4d4;
}
.ngRow.odd {
  background-color: #eff4f7;
}
.ngRow.even {
  background-color: #ffffff;
}
.ngRow.even:hover,
.ngRow.odd:hover {
  background-color: #f5f5f5;
}
.ngRow > .inactive {
  opacity: 0.5;
}
.ngVerticalBarVisible {
  height: 100%;
}
.gridSearchBar {
  border: 1px solid #d4d4d4;
  padding: 5px;
  color: #707070;
  font-weight: normal;
  background: linear-gradient(#f8f8f8 0px, #ececec 100%) repeat-x #f2f2f2;
}
.ngCellText .fa.status-active {
  color: #48bb91;
}
.ngCellText .fa.status-inactive {
  color: #cccccc;
}
/** Styles for form-based tree used for threat rules & bps rules */
.freshideas-tree-wrapper {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 20px;
}
.freshideas-tree {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.freshideas-tree .freshideas-tree {
  padding-left: 30px;
}
.freshideas-tree-node {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}
.freshideas-tree-node-content {
  display: block;
  min-height: 38px;
  margin: 5px 0;
  padding: 8px 12px;
  background: #f8faff;
  border: 1px solid #ccc;
  color: #7c9eb2;
  text-decoration: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.freshideas-tree .freshideas-tree .freshideas-tree-node-content {
  border: 1px solid #dae2ea;
}
.freshideas-tree-node-content .freshideas-tree-node-header {
  font-weight: bold;
}
.freshideas-tree-node > button {
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 1px 5px 5px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  top: 4px;
  left: 1px;
  color: #707070;
}
.freshideas-tree-node > button > i {
  display: block;
  width: 100%;
  text-align: center;
  text-indent: 0;
  font-weight: normal;
  font-size: 14px;
}
button.freshideas-tree-node-button {
  padding-right: 5px;
  color: black;
  border: 0;
  background: transparent;
}
/* override animation setting for buttons in ace.css. */
.btn {
  -webkit-transition: none;
  transition: none;
}
#title-outer {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}
#title-inner {
  position: relative;
  width: 30%;
  margin-right: auto;
  margin-left: auto;
  font-family: inherit;
  font-size: 26px;
  text-align: center;
  font-weight: 500;
  color: #ededed;
  padding-top: 5px;
}
.cloud-dashboard {
  display: block;
}
.remove-view {
  display: none;
}
.ribbon-wrapper-blue {
  width: 170px;
  height: 158px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
}
.ribbon-blue {
  font: bold 15px Sans-Serif;
  color: #333 !important;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: 0;
  top: 47px;
  width: 220px;
  background-color: #46a3f3;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#7dc0fa), to(#46a3f3));
  background-image: -webkit-linear-gradient(top, #7dc0fa, #46a3f3);
  background-image: -moz-linear-gradient(top, #7dc0fa, #46a3f3);
  background-image: -ms-linear-gradient(top, #7dc0fa, #46a3f3);
  background-image: -o-linear-gradient(top, #7dc0fa, #46a3f3);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.ribbon-blue > a:link {
  color: #333 !important;
  text-decoration: none;
}
.ribbon-blue > a:visited {
  color: #333;
  text-decoration: none;
}
.ribbon-blue > a:hover {
  color: #ffffff;
  text-shadow: rgba(80, 80, 80, 0.5) 0 1px 0;
  text-decoration: none;
}
.ribbon-blue > a:active {
  color: #333;
  text-shadow: #ffffff 0 0 2px;
  text-decoration: none;
}
.ribbon-blue:before,
.ribbon-blue:after {
  content: "";
  border-top: 3px solid #3096ed;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
}
.ribbon-blue:before {
  left: 0;
}
.ribbon-blue:after {
  right: 0;
}
.cloud-icon {
  display: block;
  padding-bottom: 20px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-20 {
  margin-top: 20px;
}
.sub-navbar {
  min-height: 35px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.nav-list > li .submenu > li.active > a {
  font-weight: bold;
}
.dropdown-menu.dropdown-close.pull-right {
  left: auto;
  right: 15px;
  min-width: 210px;
}
.dropdown-menu.dropdown-close {
  top: 45px;
  left: -5px;
}
.username-navbar {
  float: right;
  color: rgba(54, 124, 43, 0.85);
  font-weight: bold;
  margin-top: 15px;
  margin-right: 15px;
}
@media (max-width: 768px) {
  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    position: relative;
  }
  .username-navbar {
    top: 8px;
    left: 65px;
    position: absolute;
  }
  .dropdown-menu.dropdown-close.pull-right {
    margin-top: 8px;
  }
}
.btn.mover {
  display: block;
}
.btn.mover.right {
  margin-top: 24px;
}
.checkbox-align {
  float: right;
  margin-right: 100px !important;
}
.report .no-widget-padding td,
.report .no-widget-padding th {
  padding: 0px !important;
}
/* Tab */
tabs {
  display: block;
}
tabs-header {
  display: block;
}
tab-button {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
}
tab-button.current {
  background: #eeeeee;
}
tab-button + tab-button {
  border-left: 1px solid #dddddd;
}
tab-contents,
tab-content {
  display: block;
}
tab-content {
  display: none;
}
tab-content.current {
  display: block;
}
tab-contents {
  border: 1px solid #eeeeee;
  padding: 20px;
}
/* Sortable */
[sortable-item] {
  cursor: pointer;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* Required to make elements draggable in old WebKit */
  -khtml-user-drag: element;
  -webkit-user-drag: element;
}
[sortable-item].hover {
  outline: 1px dashed #666666;
  position: relative;
  z-index: 1;
}
[sortable-item].dragging {
  opacity: 0.5;
}
